<template>
  <UiPopup
    :model-value="modelValue"
    title="Apply for the program"
    primary-button-text="Apply"
    :loading="saving"
    secondary-button-text="Close"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <div class="flex flex-col gap-4 overflow-hidden">
      <p class="mb-2 px-5">
        If you want to apply for the program, select a lead and submit it as an application for participation in the
        program.
      </p>
      <UiInputSelect
        v-model="data.lead_id"
        :items="leads"
        name="lead"
        show-virtual-scroller
        placeholder="Select lead"
        :error="useGetFieldErrors(v$, ['lead_id'])"
      />
      <UiInputTextField
        v-model="data.note"
        name="additional-note"
        show-limit
        :max-length="100"
        placeholder="Additional note"
        class="!flex-col-reverse gap-1"
      />
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { required, helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import type { InputItem } from '@/types'
import { useUiStore } from '~/store/ui'

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

type Props = {
  modelValue: boolean
  programId: number
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const data = ref({
  commission_program_id: props.programId,
  lead_id: null,
  note: '',
})

const saving = ref(false)
const uiStore = useUiStore()
const leads = ref<InputItem[]>([])

const getLeads = async () => {
  try {
    leads.value = await useGetActiveLeadsItems()
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

onNuxtReady(() => getLeads())

const rules = computed(() => ({
  lead_id: { required: helpers.withMessage('The lead is required', required) },
}))

const v$ = useVuelidate(rules, data)

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return

  saving.value = true
  try {
    await applyCommissionProgram(data.value)
    uiStore.showSnackBanner(`Applied successfully`, 'success')
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    saving.value = false
  }
}
</script>

<style scoped></style>
